:root {
    --green: #04ADA9;
    --red: #FF6344;
}

* {
    -webkit-overflow-scrolling: touch;
    touch-action: manipulation;
}

html {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

body {
    margin: 0;
    font-family: 'TruenoRegular';
    font-weight: normal;
    font-style: normal;
    overflow-x: hidden;
}

p {
    margin: 0;
    -webkit-margin-before: 0;
    -webkit-margin-after: 1em;
}

h2 {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
}

.slick-center .SliderImage {
    max-height: 360px;
    width: 100%;
    height: calc(1vw * 0.6 * 38.2);
    top: 40px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.50);
}

.testimonialSlider .slick-dots .slick-active {
    width: 14px;
    height: 14px;
    opacity: 1;
    top: 2px;
}

.testimonialSlider .slick-dots li {
    width: 8px;
    height: 8px;
    opacity: 0.4;
    margin-left: 8px;
}

.testimonialSlider .slick-dots li a {
    width: 8px;
    height: 8px;
}

.testimonialSlider .slick-next {
    opacity: 0.3;
    right: -18px;
    width: 15px;
    height: 20px;
}

.testimonialSlider .slick-next:hover {
    opacity: 0.8;
}

.testimonialSlider .slick-prev {
    opacity: 0.3;
    left: -18px;
    width: 15px;
    height: 20px;
}

.testimonialSlider .slick-prev:hover {
    opacity: 0.8;
}

.testimonialSlider .slick-disabled {
    display: none;
}

.coverSlider .slick-dots .slick-active {
    width: 14px;
    height: 14px;
    opacity: 1;
    top: 2px;
}

.coverSlider .slick-dots li {
    width: 8px;
    height: 8px;
    opacity: 0.4;
    margin-left: 8px;
}

.coverSlider .slick-dots li a {
    width: 8px;
    height: 8px;
}

.slick-dots li img {
    width: 100%;
    height: 100%;
}

.slick-slide, .slick-slide * {
    outline: none !important;
}

.coverSlider .slick-track {
    margin-left: auto;
    margin-right: auto;
}

.coverSlider .slick-dots {
    bottom: 16px;
}

.testimonialSlider .slick-dots {}

a {
    text-decoration: none;
    outline: 0;
}

.react-photo-gallery--gallery img {
    border-radius: 8px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.50);
}

.dragAndDropArea {
    width: 200px;
    height: 200px;
}

.smallDragAndDropArea {
    width: 112px;
    height: 112px;
}

.dragAndDropAreaProfile {
    width: 292px;
    height: 292px;
    justify-self: center;
    align-self: center;
    display: grid;
}

.dragAndDropArea2 {
    width: 90%;
    height: 100%;
    min-height: 300px;
}

.highlight {
    color: var(--green);
}

.small {
    font-size: 10px;
}

a {
    color: #ff6344;
    text-decoration: none;
}

/* entire container, keeps perspective */

.flip-container {
    perspective: 1000px;
}

/* flip the pane when hovered */

.flipped .flipper, .flip-container.hover .flipper {
    transform: rotateY(180deg);
}

.flip-container, .front, .back {
    width: 285px;
    height: 180px;
}

/* flip speed goes here */

.flipper {
    transition: 0.6s;
    transform-style: preserve-3d;
    height: 100%;
    position: relative;
}

/* hide back of pane during swap */

.front, .back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

/* front pane, placed above back */

.front {
    z-index: 2;
    /* for firefox 31 */
    transform: rotateY(0deg);
}

/* back, initially hidden pane */

.back {
    transform: rotateY(180deg);
}

@font-face {
    font-family: TruenoBold;
    src: url("/fonts/TruenoSemiBold.otf") format("opentype");
}

@font-face {
    font-family: TruenoRegular;
    src: url("/fonts/Trueno.otf") format("opentype");
}

@font-face {
    font-family: Trueno;
    src: url("/fonts/Trueno.otf") format("opentype");
}

@font-face {
    font-family: TruenoUltraLight;
    src: url("/fonts/TruenoUltraLight.otf") format("opentype");
}

@font-face {
    font-family: TruenoLight;
    src: url("/fonts/TruenoLight.otf") format("opentype");
}

@font-face {
    font-family: TruenoUltraLightItalic;
    src: url("/fonts/TruenoUltraLightItalic.otf") format("opentype");
}

@font-face {
    font-family: OCRAExtended;
    src: url("/fonts/OCRAEXT.TTF") format("truetype");
}

:-moz-any(div[title=scrollarea]) {
    margin-right: -16px!important;
    overflow-y: scroll;
    margin-bottom: -16px!important;
    overflow-x: scroll;
}